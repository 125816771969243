/* eslint-disable no-useless-escape */
export const prepareQueryParamsFromObj = (obj, keepEmpty = false) => {
  if (obj && Object.keys(obj).length > 0) {
    return Object.keys(obj).reduce((val, key) => {
      if (keepEmpty) {
        return {
          ...val,
          [key]: obj[key],
        };
      }
      if (!keepEmpty && obj[key] !== '' && obj[key] !== undefined) {
        return {
          ...val,
          [key]: obj[key],
        };
      }
      return val;
    }, {});
  }
  return {};
};

export const getParamByName = (name, url = window.location.href) => {
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getHostNameFromURL = url => {
  try {
    const parsedURL = new URL(url);
    return parsedURL.hostname;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error parsing URL:', error);
    return '';
  }
};
