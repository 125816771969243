import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import ReduxThunk from 'redux-thunk';

import agencyReducer from './agency';
import property from './property';
import properties from './properties';
import form from './form';
import user from './user';
import files from './files';

const reducers = combineReducers({
  agency: agencyReducer,
  property,
  properties,
  form,
  user,
  files,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return reducers(state, action);
};

const middleware = [
  applyMiddleware(ReduxThunk),
  ...(window.__REDUX_DEVTOOLS_EXTENSION__
    ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
    : []),
];

const configureStore = () => {
  const store = createStore(rootReducer, {}, compose(...middleware));

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept(() => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
};

export default configureStore;
