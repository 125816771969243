export const fileToBlob = file => {
  if (!file || (file && !file.type)) {
    throw new Error('Invalid or no file provided.');
  }
  return new Blob([file], {type: file.type});
};

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
};

export const prepareFileBlob = file => {
  if (file.filename && file.filetype && file.url.includes('data:')) {
    const [part1, part2] = file.url.split(';base64,');
    return b64toBlob(part2, part1.replace('data:', ''));
  }
  if (file.name) {
    return fileToBlob(file);
  }
  return file;
};
