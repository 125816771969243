import ValidationError from './ValidationError';
import ServerError from './ServerError';
import {getObjectProp} from '../utils/helpers/general';

export const handleSuccess = response => response.data;

export const handleError = errorResponse => {
  if (!errorResponse.response) {
    return Promise.reject(new Error("Server Didn't Respond"));
  }
  const {status} = errorResponse.response;
  /*
    fetch error message from a typical response API such as ....
  */
  let {errors} = errorResponse.response.data;
  const {error} = errorResponse.response.data;
  let message =
    getObjectProp(errorResponse, 'response.data.message') ||
    getObjectProp(errorResponse, 'response.message') ||
    undefined;
  /*
    if there is a string labeled "message" in the response, assume this is the message to show the user,
    otherwise, look for messages in the error objects...
  */
  if (message === undefined || typeof message !== 'string') {
    // sometimes (e.g. PayDock, errors is named 'error')...
    errors = errors === undefined && error !== undefined ? error : errors;

    if (Object.prototype.toString.call(errors) === '[object Array]') {
      message = errors.join(' ');
    } else if (errors && status !== 422) {
      // sometimes error is an object
      message = '';
      Object.keys(errors).forEach(key => {
        if (typeof errors[key] === 'string') {
          message += `${errors[key]} `;
        }
      });
    }
  }

  switch (status) {
    case 422:
      return Promise.reject(new ValidationError(errors, message));
    case 404:
      return Promise.reject(new Error('Not Found'));
    case 403:
      return Promise.reject(new Error(message));
    case 401:
      return Promise.reject(
        new Error(
          `${
            message ? `${message}.` : `Unauthorized.`
          } You likely have too many TABS open causing an error with the system. Please LOG OUT, close all the other tabs, and try again.`
        )
      );
    default:
      return Promise.reject(
        new ServerError(
          500,
          `There appears to be a problem with your profile, but don't worry, a simple reset should sort it out. Please click on the link below and enter your email address. If the problem persists, contact info@tenant.co.nz for assistance.`
        )
      );
  }
};
