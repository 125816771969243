import React from 'react';
import PropTypes from 'prop-types';
import {motion, AnimatePresence} from 'framer-motion';
import {CircularProgress, makeStyles} from '@tenancy.nz/design-system';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    bottom: 0,
    background: 'rgba(255,255,255,0.7)',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const fadeEnd = {
  scale: 1,
  translateY: 1,
  opacity: 1,
};

const fadeStart = {
  scale: 0.99,
  translateY: -5,
  opacity: 0,
};

const PageLoader = ({show}) => {
  const classes = useStyles();
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className={classes.root}
          initial={fadeStart}
          animate={fadeEnd}
          exit={fadeStart}
          transition={{
            duration: 0.1,
            ease: 'easeOut',
          }}>
          <CircularProgress size={200} thickness={1} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

PageLoader.propTypes = {
  show: PropTypes.bool,
};

PageLoader.defaultProps = {
  show: false,
};

export default PageLoader;
