import React from 'react';
import PropTypes from 'prop-types';
import {motion, AnimatePresence} from 'framer-motion';
import {CircularProgress, makeStyles} from '@tenancy.nz/design-system';
import SendIcon from '@material-ui/icons/Send';
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    bottom: 0,
    background: 'rgba(255,255,255,0.7)',
    zIndex: 100,

    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  plane: {
    transform: 'rotate(-30deg)',
    fontSize: 72,
  },
}));

const fadeEnd = {
  scale: 1,
  translateY: 1,
  opacity: 1,
};

const fadeStart = {
  scale: 0.99,
  translateY: -5,
  opacity: 0,
};

const SubmissionLoader = ({show}) => {
  const classes = useStyles();
  return (
    <AnimatePresence exitBeforeEnter>
      {show && (
        <motion.div
          className={classes.root}
          initial={fadeStart}
          animate={fadeEnd}
          exit={fadeStart}
          transition={{
            delay: 0.25,
            duration: 0.25,
            ease: 'easeOut',
          }}>
          <div>
            <CircularProgress size={200} thickness={1} />
          </div>
          <div>
            <SendIcon color="primary" className={classes.plane} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

SubmissionLoader.propTypes = {
  show: PropTypes.bool,
};

SubmissionLoader.defaultProps = {
  show: false,
};

export default SubmissionLoader;
