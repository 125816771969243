import {combineReducers} from 'redux';

const details = (state = {name: '', phone: '', logo: '', id: null}, action) => {
  switch (action.type) {
    case 'SET_AGENCY_DETAILS':
      return {...state, ...action.details};
    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case 'SET_AGENCY_LOADING':
      return action.loading;
    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case 'SET_AGENCY_ERROR':
      return action.error;
    default:
      return state;
  }
};

const services = (state = null, action) => {
  switch (action.type) {
    case 'SET_AGENCY_SERVICES':
      return action.services;
    default:
      return state;
  }
};

const settings = (state = null, action) => {
  switch (action.type) {
    case 'SET_AGENCY_SETTINGS':
      return action.settings;
    default:
      return state;
  }
};

const returnUrl = (state = '', action) => {
  switch (action.type) {
    case 'SET_RETURN_URL':
      return action.returnUrl;
    default:
      return state;
  }
};

const agentEmail = (state = '', action) => {
  switch (action.type) {
    case 'SET_AGENT_EMAIL':
      return action.agentEmail;
    default:
      return state;
  }
};

const agency = combineReducers({
  agentEmail,
  details,
  loading,
  error,
  services,
  returnUrl,
  settings,
});

export default agency;
