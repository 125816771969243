/* eslint-disable no-plusplus */
import {env} from './general';
import {getHostNameFromURL} from './url';

export const getDefaultCookieDomain = () => {
  const appUrl = env('REACT_APP_URL');
  const parts = getHostNameFromURL(appUrl).split('.').slice(1);
  return `.${parts.join('.')}`;
};

/**
 *
 * @param {string} name - Name of the cookie
 * @returns {mixed}
 */
export const getCookie = name => {
  const cname = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  // eslint-disable-next-line, no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      // eslint-disable-next-line
      c = c.substring(1);
    }
    if (c.indexOf(cname) === 0) {
      return c.substring(cname.length, c.length);
    }
  }
  return '';
};

/**
 *
 * @param {string} name - Name of the cookie
 * @param {string} value - Value of the cookie
 * @param {string} expiry - Expiry time in UTC string
 * @param {string} domain - Domain which cookie belongs to
 *
 * @return {void}
 */
export const setCookie = (
  name,
  value,
  expiry = '',
  domain = getDefaultCookieDomain()
) => {
  const cname = `${name}=${value};`;
  const cexpiry = expiry ? `expires=${expiry};` : ``;
  const cdomain = domain ? `domain=${domain};` : ``;
  // eslint-disable-next-line
  document.cookie = `${cname}${cexpiry}${cdomain}path=/`;
};

/**
 *
 * @param {string} name - Name of the cookie
 * @param {string} domain - Domain which cookie belongs to
 *
 * @return {void}
 */
export const deleteCookie = (name, domain = getDefaultCookieDomain()) => {
  const cdomain = domain ? `domain=${domain};` : ``;
  // eslint-disable-next-line
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;${cdomain}path=/`;
};
