const files = (state = [], action) => {
  switch (action.type) {
    case 'ADD_FILE':
      return state.concat(action.file);
    default:
      return state;
  }
};

export default files;
