import TenancyPortalAPI from '../api/TenancyPortal';
import TrackerService from '../api/TrackerService';

export const setUpdating = updating => async dispatch =>
  dispatch({type: 'SET_UPDATING', updating});

export const updateFormData = data => async dispatch =>
  dispatch({type: 'UPDATE_FORM_DATA', data});

export const markStepComplete = (step, steps) => async dispatch => {
  const updatedSteps = [...steps];
  updatedSteps[step].completed = true;
  updatedSteps[step + 1].disabled = false;
  dispatch({type: 'SET_STEPS', steps: updatedSteps});
};

// applicant id
const setApplicationComplete = complete => async dispatch => {
  dispatch({type: 'SET_APPLICATION_COMPLETE', complete});
};

const setApplicationCompleteId = applicationId => async dispatch => {
  dispatch({type: 'SET_APPLICATION_COMPLETE_ID', applicationId});
};

export const setProcessing = processing => async dispatch => {
  dispatch({type: 'SET_PROCESSING', processing});
};

export const setStep = step => async dispatch =>
  dispatch({type: 'SET_STEP', step});

export const stepForward = () => async dispatch =>
  dispatch({type: 'STEP_FORWARD'});

export const stepBack = () => async dispatch => dispatch({type: 'STEP_BACK'});

const setSubmissionError = error => async dispatch =>
  dispatch({type: 'SET_SUBMISSION_ERROR', error});

const setSubmitting = submitting => async dispatch =>
  dispatch({type: 'SET_SUBMITTING', submitting});

const setUpdatingProfile = updating => async dispatch =>
  dispatch({type: 'SET_UPDATING_PROFILE', updating});

const setUpdatingProfileError = error => async dispatch =>
  dispatch({type: 'SET_UPDATING_PROFILE_ERROR', error});

const setUpdatingProfileComplete = complete => async dispatch =>
  dispatch({type: 'SET_PROFILE_COMPLETE', complete});

export const setStepError = (step, error, steps) => async dispatch => {
  const updatedSteps = [...steps];
  updatedSteps[step].error = error;
  dispatch({type: 'SET_STEPS', steps: updatedSteps});
};

// eslint-disable-next-line no-unused-vars
const setStepErrors = errors => async dispatch => {
  const steps = [
    {optional: false, label: 'Details', error: false, completed: false},
    {optional: false, label: 'References', error: false, completed: false},
    {optional: false, label: 'Additional Info', error: false, completed: false},
    {optional: false, label: 'Contact', error: false, completed: false},
    {optional: false, label: 'Moving', error: false, completed: false},
  ];

  const detailsFieldNames = [
    'co_applicants',
    'other_applicants',
    'tenants.bio',
    'tenants.dob',
    'tenants.email',
    'tenants.first_name',
    'tenants.guarantor',
    'tenants.guarantor.email',
    'tenants.guarantor.first_name',
    'tenants.guarantor.last_name',
    'tenants.guarantor.phone',
    'tenants.has_guarantor',
    'tenants.home_phone',
    'tenants.identity.dl_file_id',
    'tenants.identity.dl_back_file_id',
    'tenants.identity.dl_number',
    'tenants.identity.dl_version',
    'tenants.identity.eighteenplus_expiry',
    'tenants.identity.eighteenplus_file_id',
    'tenants.identity.eighteenplus_number',
    'tenants.identity.extra_id_expiry',
    'tenants.identity.extra_id_file_id',
    'tenants.identity.extra_id_number',
    'tenants.identity.extra_id_type',
    'tenants.identity.pp_expiry',
    'tenants.identity.pp_file_id',
    'tenants.identity.pp_number',
    'tenants.last_name',
    'tenants.middle_names',
    'tenants.mobile',
    // 'tenants.mobile_time',
    // 'tenants.non_citizen_details',
    // 'tenants.nz_citizen',
    'tenants.preferred_name',
    'tenants.profile_photo_id',
    // 'tenants.proof_of_address_file_id',
    // 'tenants.proof_of_income_file_id',
    // 'tenants.proof_of_others_file_id',
    'tenants.req_num_adults',
    'tenants.req_num_children',
    'tenants.smokers',
    // 'tenants.winz_case_manager_email',
    // 'tenants.winz_number',
    'tenants.work_phone',
    'tenants.documents',
  ];

  const referencesFieldNames = [
    'tenants.references',
    // 'tenants.employment.work_status_employment',
    // 'tenants.employment.work_status_benefit',
    // 'tenants.employment.work_status_self_employed',
    // 'tenants.employment.employer',
    // 'tenants.employment.occupation',
    // 'tenants.employment.employer_contact',
    // 'tenants.employment.employer_email',
    // 'tenants.employment.employer_time_years',
    // 'tenants.employment.employer_time_months',
    // 'tenants.employment.employer_phone',
    // 'tenants.employment.income',
    // 'tenants.employment.employer_street_1',
    // 'tenants.employment.employer_street_2',
    // 'tenants.employment.employer_suburb',
    // 'tenants.employment.employer_city',
    // 'tenants.employment.employer_postcode',
    'tenants.addresses',
    'tenants.student',
    'tenants.course_end_date',
    'tenants.institution_name',
    'tenants.course_name',
  ];

  const requirementsFieldNames = [
    'tenants.vehicles',
    'tenants.vehicle_parking',
    'tenants.pets',
    'tenants.pet_details',
    'tenants.will_store_firearms',
    'tenants.has_firearm_license',
    'tenants.req_num_bedrooms',
    'tenants.req_num_garaging',
    'tenants.req_num_bathrooms',
    'tenants.req_fencing',
    'tenants.notes',
  ];

  const historyFieldNames = [
    'tenants.emrg_name',
    'tenants.emrg_phone',
    'tenants.emrg_relationship',
    'tenants.emrg_street_1',
    // 'tenants.emrg_street_2',
    'tenants.emrg_suburb',
    'tenants.emrg_city',
    'tenants.emrg_postcode',
    'tenants.has_termination_or_tribunal',
    'tenants.termination_or_tribunal_details',
    'tenants.has_been_incarcerated',
    'tenants.incarceration_details',
    // 'tenants.consumer_finance',
    // 'tenants.consumer_finance_amount',
    'tenants.owing_landlord_pm',
    'tenants.owing_landlord_pm_amount',
  ];

  let activeStep = 0;

  if (
    errors &&
    Object.keys(errors).some(name => detailsFieldNames.indexOf(name) !== -1)
  ) {
    steps[0].error = true;
    activeStep = 1;
  }

  if (
    errors &&
    Object.keys(errors).some(name => referencesFieldNames.indexOf(name) !== -1)
  ) {
    steps[1].error = true;
    activeStep = activeStep || 2;
  }

  if (
    errors &&
    Object.keys(errors).some(
      name => requirementsFieldNames.indexOf(name) !== -1
    )
  ) {
    steps[2].error = true;
    activeStep = activeStep || 3;
  }

  if (
    errors &&
    Object.keys(errors).some(name => historyFieldNames.indexOf(name) !== -1)
  ) {
    steps[3].error = true;
    activeStep = activeStep || 4;
  }

  dispatch({type: 'SET_STEPS', steps});

  // set active step to first step with errors
  if (errors) {
    dispatch(setStep(activeStep > 0 ? activeStep - 1 : 0));
  }

  window.scrollTo({top: 0, behavior: 'smooth'});
};

export const applicationSubmission = values => async dispatch => {
  dispatch(setSubmitting(true));
  return TenancyPortalAPI.post('tenancy/apply', values)
    .then(({data}) => {
      dispatch(setSubmitting(false));
      dispatch(setApplicationComplete(true));
      dispatch(
        setApplicationCompleteId(
          data && data.applying_applicant && data.applying_applicant.id
        )
      );
      return {status: 'success', data};
    })
    .catch(error => {
      dispatch(setSubmissionError(error));
      dispatch(setSubmitting(false));
      const {errors} = error;
      dispatch(setStepErrors(errors));

      if (errors) {
        // loop through error response and create a nested objects with string values...
        const result = {};
        Object.keys(errors).forEach(key => {
          const parts = key.split('.');
          let target = result;
          while (parts.length > 1) {
            const part = parts.shift();
            // eslint-disable-next-line no-multi-assign
            target = target[part] = target[part] || {};
          }

          if (typeof target === 'object') {
            // eslint-disable-next-line prefer-destructuring
            target[parts[0]] = errors[key][0];
          }
        });

        return result;
      }

      return error;
    });
};

export const doProfileUpdate = values => async dispatch => {
  dispatch(setUpdatingProfile(true));
  return TenancyPortalAPI.post('tenant', values)
    .then(response => {
      dispatch(setUpdatingProfile(false));
      dispatch(setUpdatingProfileComplete(true));
      return response;
    })
    .catch(error => {
      dispatch(setUpdatingProfile(false));
      dispatch(setUpdatingProfileError(true));
      return error;
    });
};

export const assignApplicationToViewingBookings = applicationId =>
  TrackerService.post(`bookings/application/${applicationId}`);
