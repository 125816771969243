import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Box,
  Container,
  Button,
  withStyles,
} from '@tenancy.nz/design-system';

const styles = theme => ({
  doh: {
    color: theme.palette.primary.main,
    fontSize: 78,
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
  },
  sorry: {
    color: theme.palette.text.primary,
    fontSize: 24,
    textAlign: 'center',
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  lol: {
    color: theme.palette.default.light,
    fontSize: 16,
  },
  image: {
    width: '60%',
    maxWidth: 250,
    display: 'block',
    height: 'auto',
    margin: '0 auto',
  },
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
    this.resetState = this.resetState.bind(this);
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error(error);
    // eslint-disable-next-line no-console
    console.error(errorInfo);
  }

  resetState() {
    this.setState({hasError: false});
    // eslint-disable-next-line react/destructuring-assignment
    this.props.resetState();
  }

  render() {
    const {hasError} = this.state;
    const {children, classes} = this.props;
    if (hasError) {
      return (
        <Container maxWidth="md">
          <Box
            sx={{
              p: 5,
            }}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={7}>
                <Box
                  sx={{
                    py: 5,
                  }}>
                  <Typography gutterBottom className={classes.doh}>
                    On no!
                  </Typography>
                  <Typography className={classes.sorry}>
                    Something went wrong. Please refresh the page and try again.
                    If you&apos; still have issues, we are here to help so
                    don&apos;t hesitate{' '}
                    <a
                      href="https://tenant.co.nz/contact/"
                      target="_blank"
                      rel="noopener noreferrer">
                      contact us
                    </a>
                    .
                  </Typography>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Box
                        sx={{
                          p: 5,
                        }}>
                        <Button onClick={this.resetState}>Try again</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    py: 5,
                  }}>
                  <img
                    src="images/crying.jpg"
                    alt=""
                    className={classes.image}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.shape({
    doh: PropTypes.string,
    sorry: PropTypes.string,
    lol: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  resetState: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  resetState: () => dispatch({type: 'RESET'}),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ErrorBoundary));
