import TenancyPortalAPI from '../api/TenancyPortal';
import {setProperties} from './properties';
import {setProperty} from './property';

const setAgencyLoading = loading => async dispatch =>
  dispatch({type: 'SET_AGENCY_LOADING', loading});

const setAgencyError = error => async dispatch =>
  dispatch({type: 'SET_AGENCY_ERROR', error});

const setAgencyServices = services => async dispatch =>
  dispatch({type: 'SET_AGENCY_SERVICES', services});

const setInitialApplyHereFormValues = initialValues => async dispatch =>
  dispatch({type: 'SET_INITIAL_APPLY_HERE_VALUES', initialValues});

const setCoapplicants = coapplicants => async dispatch =>
  dispatch({type: 'SET_COAPPLICANTS', coapplicants});

const setApplicant = applicant => async dispatch =>
  dispatch({type: 'SET_APPLICANT', applicant});

// eslint-disable-next-line no-unused-vars
const setAgencySettings = settings => async dispatch =>
  dispatch({
    type: 'SET_AGENCY_SETTINGS',
    settings,
  });

export const setReturnUrl = returnUrl => async dispatch =>
  dispatch({type: 'SET_RETURN_URL', returnUrl});

export const setAgentEmail = agentEmail => async dispatch =>
  dispatch({type: 'SET_AGENT_EMAIL', agentEmail});

const setAgencyDetails =
  ({
    // eslint-disable-next-line camelcase
    company_name_tenancy_application,
    // eslint-disable-next-line camelcase
    address_physical_city,
    phone,
    logo,
    id,
    franchise,
    // eslint-disable-next-line camelcase
    application_form_settings,
    // eslint-disable-next-line camelcase
    has_viewme_access,
    // eslint-disable-next-line camelcase
    client_code,
  }) =>
  async dispatch => {
    dispatch({
      type: 'SET_AGENCY_DETAILS',
      details: {
        name: company_name_tenancy_application,
        location: address_physical_city,
        // eslint-disable-next-line camelcase
        enableViewingTracker: has_viewme_access === 'yes',
        logo,
        phone,
        id,
        franchiseName: franchise ? franchise.mh_name : '',
        franchiseLogo: franchise ? franchise.mh_logo : '',
        settings: application_form_settings,
        clientCode: client_code,
      },
    });
  };

export const fetchApplicationDetails = applicationID => async dispatch => {
  dispatch(setAgencyLoading(true));
  TenancyPortalAPI.get(`tenancy/apply/uuid/${applicationID}`)
    .then(response => {
      const {
        data: {
          agency,
          property,
          application,
          applicant,
          services,
          franchise,
          // eslint-disable-next-line camelcase
          co_applicants,
          // eslint-disable-next-line camelcase
          return_url,
        },
      } = response;

      dispatch(setAgencyServices(services));
      dispatch(setAgencySettings(agency.application_form_settings));
      dispatch(setApplicant(applicant));
      dispatch(setCoapplicants(co_applicants));
      dispatch(setAgencyDetails({...agency, franchise}));
      dispatch(setProperty(property));
      dispatch(setInitialApplyHereFormValues(application));
      // eslint-disable-next-line camelcase
      if (return_url) {
        dispatch(setReturnUrl(return_url));
      }
      dispatch(setAgencyLoading(false));
    })
    .catch(error => {
      dispatch(setAgencyError(error));
      dispatch(setAgencyLoading(false));
    });
};

export const fetchAgencyDetails = agencyOrPropertyID => async dispatch => {
  dispatch(setAgencyLoading(true));
  TenancyPortalAPI.get(`tenancy/apply/${agencyOrPropertyID}`)
    .then(response => {
      const {
        // eslint-disable-next-line camelcase
        data: {agency, properties, property, services, franchise, return_url},
      } = response;
      dispatch(setAgencyDetails({...agency, franchise}));
      dispatch(setAgencyServices(services));
      dispatch(setAgencySettings(agency.application_form_settings));
      // eslint-disable-next-line camelcase
      if (return_url) {
        dispatch(setReturnUrl(return_url));
      }
      if (
        properties !== null &&
        properties !== undefined &&
        properties.length > 0
      ) {
        dispatch(setProperties(properties));
      }
      if (property !== null && property !== undefined) {
        dispatch(setProperty(property));
      }
      dispatch(setAgencyLoading(false));
    })
    .catch(error => {
      dispatch(setAgencyError(error));
      dispatch(setAgencyLoading(false));
    });
};
