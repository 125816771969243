// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga4';

export const eventName = 'ApplicationStage1Complete';
export const eventCategory = 'Application';
export const eventAction = 'Submit';
export const eventLabel = 'Application Form Submitted';

export default (eventArgs = {}) =>
  ReactGA.event(eventName, {
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
    transport: 'xhr',
    ...eventArgs,
  });
