/* eslint-disable import/prefer-default-export */

export const stripSpaces = str => String(str).replace(/\s/g, '');

export const jsonEncode = data => JSON.stringify(data);

export const jsonDecode = str => JSON.parse(str);

export const sanitizeAddress = str => {
  const prepared = String(str).trim();
  if (!prepared) {
    return '';
  }
  return prepared.replace(/[^A-Za-z0-9\s/]/gi, '');
};
