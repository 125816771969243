import {DEFAULT_MAX_UPLOAD_SIZE} from '../constants';
import {isObject} from './boolean';

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const env = (name, fallback) => {
  if (process && process.env && process.env[name] !== undefined) {
    return process.env[name];
  }
  return fallback;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
};

export const getMaxUploadSize = () =>
  parseInt(env('REACT_APP_MAX_UPLOAD_SIZE', DEFAULT_MAX_UPLOAD_SIZE), 10);

/**
 * Gets the value of prop of provided object. Empty if not found
 *
 * @param {object} obj
 * @param {string} prop
 *
 * @return {mixed}
 */
export const getObjectProp = (obj, prop) =>
  prop
    .split('.')
    .reduce((value, key) => (isObject(value) && value[key]) || '', obj);

export const prepareSettings = obj => {
  const defaultSetting = {
    active: true,
    dropdown: true,
    listings: true,
    manual: true,
  };
  if (isObject(obj)) {
    return Object.assign(defaultSetting, obj);
  }
  return defaultSetting;
};
