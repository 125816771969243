import axios from 'axios';
import TenancyPortalAPI from '../api/TenancyPortal';
import {isArray, deleteCookie, setCookie} from '../utils/helpers';
import {KEY_AUTHENTICATED, KEY_AUTHENTICATION_TOKEN} from '../utils/constants';

const setLoggingIn = () => dispatch => dispatch({type: 'LOGGING_IN'});
const setRegistering = () => dispatch => dispatch({type: 'REGISTERING'});
const setLoggedIn = authenticated => dispatch =>
  dispatch({type: 'LOGIN_SUCCESS', authenticated});

const setLoggedOut = () => dispatch => dispatch({type: 'LOGOUT'});
const setRegistered = authenticated => dispatch =>
  dispatch({type: 'REGISTRATION_SUCCESS', authenticated});
const setLoginError = error => dispatch =>
  dispatch({type: 'LOGIN_ERROR', error});
const setRegistrationError = error => dispatch =>
  dispatch({type: 'REGISTRATION_ERROR', error});

export const setSessionTime = time => dispatch =>
  dispatch({type: 'SET_SESSION_TIME', time});

export const setProfileImageUrl = url => dispatch =>
  dispatch({
    type: 'SET_PROFILE_PHOTO_URL',
    profileImageUrl: url,
  });

const setProfile = data => dispatch => {
  // for some reason profile_photo_id comes as a URL,
  // while every other image is a file ID.
  // The POST endpoint expects an ID, so I have to remove this
  // from here and store the URL somewhere else
  // or it will mess everything up...
  const profile = {...data};
  // eslint-disable-next-line camelcase
  dispatch(setProfileImageUrl(data.profile.profile_photo_id));
  delete profile.profile.profile_photo_id;
  dispatch({type: 'SET_PROFILE_DATA', data: profile});
};

const setProfileError = error => dispatch =>
  dispatch({type: 'SET_PROFILE_ERROR', error});

export const startApplication = started => async dispatch =>
  dispatch({type: 'USER_HAS_STARTED_APPLICATION', started});

export const skippedRegistration = skipped => async dispatch =>
  dispatch({type: 'USER_HAS_SKIPPED_REGISTRATION', skipped});

export const fetchProfileData = () => async dispatch => {
  TenancyPortalAPI.get(
    'tenant?include=profile,identity,addresses,residents,references,pets,documents,guarantor'
  )
    .then(({data}) => {
      dispatch(setLoggedIn(true));
      dispatch(setSessionTime(new Date()));
      dispatch(setProfile(data));
      dispatch(setProfileError(null));
    })
    .catch(error => {
      deleteCookie(KEY_AUTHENTICATION_TOKEN);
      deleteCookie(KEY_AUTHENTICATED);
      return dispatch(setProfileError(error));
    });
};

export const doLogin = credentials => async dispatch => {
  dispatch(setLoggingIn(true));
  return TenancyPortalAPI.post('auth/login', credentials)
    .then(response => {
      setCookie(KEY_AUTHENTICATION_TOKEN, response.access_token);
      setCookie(KEY_AUTHENTICATED, 'true');
      dispatch(setLoggedIn(true));
      dispatch(setSessionTime(new Date()));
    })
    .catch(error => {
      dispatch(setLoginError(error));
      const {errors} = error;
      return errors || null;
    })
    .catch(() => {
      deleteCookie(KEY_AUTHENTICATION_TOKEN);
      deleteCookie(KEY_AUTHENTICATED);
    });
};

export const doLogout = () => async dispatch => {
  dispatch(setLoggingIn(true));
  TenancyPortalAPI.get('auth/logout')
    .then(() => {
      deleteCookie(KEY_AUTHENTICATION_TOKEN);
      deleteCookie(KEY_AUTHENTICATED);
      dispatch(setSessionTime(null));
      dispatch(setLoggedOut());
    })
    // eslint-disable-next-line no-console
    .catch(error => console.error(error));
};

export const doRegistration = values => async dispatch => {
  dispatch(setRegistering(true));
  return TenancyPortalAPI.post('tenant/register', values)
    .then(() => {
      dispatch(setRegistered(true));
      dispatch(setLoggingIn(true));
      return TenancyPortalAPI.post('auth/login', {
        username: values.email,
        password: values.password,
      })
        .then(response => {
          setCookie(KEY_AUTHENTICATION_TOKEN, response.access_token);
          setCookie(KEY_AUTHENTICATED, 'true');
          dispatch(setLoggedIn(true));
          dispatch(setSessionTime(new Date()));
          return 'success';
        })
        .catch(error => {
          dispatch(setLoginError(error));
          const {errors} = error;
          return errors || null;
        });
    })
    .catch(error => {
      dispatch(setRegistrationError(error));
      const {errors} = error;
      return errors || null;
    });
};

export const doPetDelete = async id =>
  TenancyPortalAPI.delete(`tenant/pets/${id}`).then(response => response);

export const doPetDeleteBulk = async ids =>
  new Promise((resolve, reject) => {
    if (isArray(ids) && ids.length >= 0) {
      axios
        .all(ids.map(id => doPetDelete(id)))
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    } else {
      reject();
    }
  });

export const doResidentDelete = async id =>
  TenancyPortalAPI.delete(`tenant/residents/${id}`).then(response => response);

export const doResidentDeleteBulk = async ids =>
  new Promise((resolve, reject) => {
    if (isArray(ids) && ids.length >= 0) {
      axios
        .all(ids.map(id => doResidentDelete(id)))
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    } else {
      reject();
    }
  });

export const doVehicleDelete = async id =>
  TenancyPortalAPI.delete(`tenant/vehicles/${id}`).then(response => response);

export const doDocumentDelete = async id =>
  TenancyPortalAPI.delete(`tenant/documents/${id}`).then(response => response);

export const doReferenceDelete = async id =>
  TenancyPortalAPI.delete(`tenant/references/${id}`).then(response => response);

export const doAddressDelete = async id =>
  TenancyPortalAPI.delete(`tenant/addresses/${id}`).then(response => response);

export const addFile = (id, url) => dispatch =>
  dispatch({type: 'ADD_FILE', file: {id, url}});

export const getFileURL = async fileID =>
  TenancyPortalAPI.get(`tenant/file/link/${fileID}`)
    .then(response => response.url)
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error('Failed to open attachment', error);
      return null;
    });

export const setFirstName = firstName => dispatch =>
  dispatch({type: 'SET_FIRST_NAME', firstName});
export const setLastName = lastName => dispatch =>
  dispatch({type: 'SET_LAST_NAME', lastName});
export const setEmail = email => dispatch =>
  dispatch({type: 'SET_EMAIL', email});
export const setMobile = mobile => dispatch =>
  dispatch({type: 'SET_MOBILE', mobile});
