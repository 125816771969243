import {combineReducers} from 'redux';

const data = (state = {}, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return {};
    case 'UPDATE_FORM_DATA':
      return {...state, ...action.data};
    default:
      return state;
  }
};

const steps = (
  state = [
    {optional: false, label: 'Details', error: false, completed: false},
    {
      optional: false,
      label: 'References',
      error: false,
      completed: false,
      disabled: true,
    },
    {
      optional: false,
      label: 'Additional Info',
      error: false,
      completed: false,
      disabled: true,
    },
    {
      optional: false,
      label: 'Contact',
      error: false,
      completed: false,
      disabled: true,
    },
    {
      optional: false,
      label: 'Moving',
      error: false,
      completed: false,
      disabled: true,
    },
  ],
  action
) => {
  switch (action.type) {
    case 'LOGOUT':
      return [
        {optional: false, label: 'Details', error: false, completed: false},
        {
          optional: false,
          label: 'References',
          error: false,
          completed: false,
          disabled: true,
        },
        {
          optional: false,
          label: 'Additional Info',
          error: false,
          completed: false,
          disabled: true,
        },
        {
          optional: false,
          label: 'Contact',
          error: false,
          completed: false,
          disabled: true,
        },
        {
          optional: false,
          label: 'Moving',
          error: false,
          completed: false,
          disabled: true,
        },
      ];
    case 'SET_STEPS':
      return action.steps;
    default:
      return state;
  }
};

const activeStep = (state = 0, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return 0;
    case 'SET_STEP':
      return action.step;
    case 'STEP_FORWARD':
      return state + 1;
    case 'STEP_BACK':
      return state - 1;
    default:
      return state;
  }
};

const submissionError = (state = null, action) => {
  switch (action.type) {
    case 'SET_SUBMISSION_ERROR':
      return action.error;
    default:
      return state;
  }
};

const submitting = (state = false, action) => {
  switch (action.type) {
    case 'SET_SUBMITTING':
      return action.submitting;
    default:
      return state;
  }
};

const processing = (state = false, action) => {
  switch (action.type) {
    case 'SET_PROCESSING':
      return action.processing;
    default:
      return state;
  }
};

const updating = (state = false, action) => {
  switch (action.type) {
    case 'SET_UPDATING':
      return action.updating;
    default:
      return state;
  }
};

const complete = (state = false, action) => {
  switch (action.type) {
    case 'SET_APPLICATION_COMPLETE':
      return action.complete;
    default:
      return state;
  }
};

const completeId = (state = false, action) => {
  switch (action.type) {
    case 'SET_APPLICATION_COMPLETE_ID':
      return action.applicationId;
    default:
      return state;
  }
};

const updatingProfile = (state = false, action) => {
  switch (action.type) {
    case 'SET_UPDATING_PROFILE':
      return action.updating;
    default:
      return state;
  }
};

const updatingProfileError = (state = false, action) => {
  switch (action.type) {
    case 'SET_UPDATING_PROFILE_ERROR':
      return action.error;
    default:
      return state;
  }
};

const profileUpdateComplete = (state = false, action) => {
  switch (action.type) {
    case 'SET_PROFILE_COMPLETE':
      return action.complete;
    default:
      return state;
  }
};

const initialApplyHereFormValues = (state = null, action) => {
  switch (action.type) {
    case 'SET_INITIAL_APPLY_HERE_VALUES':
      return action.initialValues;
    default:
      return state;
  }
};

const coapplicants = (state = null, action) => {
  switch (action.type) {
    case 'SET_COAPPLICANTS':
      return action.coapplicants;
    default:
      return state;
  }
};

const formReducer = combineReducers({
  data,
  steps,
  activeStep,
  submissionError,
  submitting,
  updating,
  complete,
  completeId,
  updatingProfile,
  profileUpdateComplete,
  updatingProfileError,
  initialApplyHereFormValues,
  coapplicants,
  processing,
});

export default formReducer;
