import axios from 'axios';
import {handleSuccess, handleError} from './helpers';
import {getCookie, deleteCookie} from '../utils/helpers';
import {KEY_AUTHENTICATED, KEY_AUTHENTICATION_TOKEN} from '../utils/constants';

const TenancyPortalAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/`,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
  withCredentials: true,
});

TenancyPortalAPI.interceptors.response.use(handleSuccess, handleError);

TenancyPortalAPI.interceptors.request.use(
  config => {
    const auth = getCookie(KEY_AUTHENTICATED);
    const token = getCookie(KEY_AUTHENTICATION_TOKEN);
    if (token && auth === 'true') {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    } else if (!token && auth === 'true') {
      deleteCookie(KEY_AUTHENTICATED);
      // eslint-disable-next-line
      window.location.href = '/';
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default TenancyPortalAPI;
