export default class ServerError extends Error {
  constructor(code = 500, message = 'A server error occurred') {
    super(message);
    this.name = this.constructor.name;
    this.code = code;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  getCode = () => this.code;
}
