export default class ValidationError extends Error {
  constructor(errors, message = 'A validation error occurred') {
    super(message);
    this.name = this.constructor.name;
    this.errors = errors;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  getErrors() {
    return this.errors;
  }
}
