const property = (state = null, action) => {
  switch (action.type) {
    case 'SET_PROPERTY':
      return action.property;
    default:
      return state;
  }
};

export default property;
