import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {motion, useAnimation} from 'framer-motion';
import {useHistory} from 'react-router-dom';
import PageLoader from '../PageLoader';

const fadeEnd = {
  // scale: 1,
  // translateY: 1,
  opacity: 1,
  transition: {delay: 0, duration: 0.15, ease: 'easeOut'},
};

const fadeStart = {
  // scale: 0.99,
  // translateY: -5,
  opacity: 0,
  transition: {delay: 0, duration: 0.15, ease: 'easeOut'},
};

const PageTransition = ({children, showPageLoader, setShowPageLoader}) => {
  const controls = useAnimation();
  const history = useHistory();

  useEffect(
    () =>
      history.listen(async state => {
        setShowPageLoader(true);
        await controls.start(fadeStart);

        if (state && state.hash) {
          // Push onto callback queue so it runs after the DOM is updated,
          // this is required when navigating from a different page so that
          // the element is rendered on the page before trying to getElementById.
          setTimeout(() => {
            const id = state.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView();
            }
          }, 0);
        } else {
          window.scrollTo(0, 0);
        }
        controls.start(fadeEnd);
        setTimeout(() => setShowPageLoader(false), 200);
      }),
    []
  );

  return (
    <>
      <PageLoader show={showPageLoader} />
      <motion.div animate={controls}>{children}</motion.div>
    </>
  );
};

PageTransition.propTypes = {
  children: PropTypes.node.isRequired,
  showPageLoader: PropTypes.bool,
  setShowPageLoader: PropTypes.func.isRequired,
};

PageTransition.defaultProps = {
  showPageLoader: false,
};

export default PageTransition;
