import React from 'react';
import PropTypes from 'prop-types';
import {motion, AnimatePresence} from 'framer-motion';

const fadeEnd = {
  // scale: 1,
  // translateY: 1,
  opacity: 1,
};

const fadeStart = {
  // scale: 0.99,
  // translateY: -5,
  opacity: 0,
};

const Fade = ({children, show, withAnimatePresence, fadeOut}) =>
  withAnimatePresence && fadeOut ? (
    <AnimatePresence exitBeforeEnter={fadeOut}>
      {show && (
        <motion.div
          initial={fadeStart}
          animate={fadeEnd}
          exit={fadeStart}
          transition={{delay: 0, duration: 0.15, ease: 'easeOut'}}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  ) : (
    <>
      {show && (
        <motion.div
          initial={fadeStart}
          animate={fadeEnd}
          exit={fadeOut ? fadeStart : null}
          transition={{delay: 0, duration: 0.15, ease: 'easeOut'}}>
          {children}
        </motion.div>
      )}
    </>
  );

Fade.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.node,
  show: PropTypes.bool,
  withAnimatePresence: PropTypes.bool,
  fadeOut: PropTypes.bool,
};

Fade.defaultProps = {
  show: false,
  children: '',
  withAnimatePresence: true,
  fadeOut: true,
};

export default Fade;
