/* eslint-disable import/prefer-default-export */

/**
 * @type {number}
 */
export const DEFAULT_MAX_UPLOAD_SIZE = 5 * 1024 * 1024;

/**
 * Key which creates local storage entry to remember authentication
 *
 * @type {string}
 */
export const KEY_AUTHENTICATED = 'authenticated';

/**
 * Cookie key which holds the value of auth token
 *
 * @type {string}
 */
export const KEY_AUTHENTICATION_TOKEN = 'api-token';
