/**
 * Check if the provided var is empty
 *
 * @param {any} val
 *
 * @return {boolean}
 */
export const isEmpty = val => {
  if (
    val === false ||
    val === null ||
    val === undefined ||
    val === '' ||
    (Array.isArray(val) && val.length === 0) ||
    (typeof val === 'object' && Object.keys(val).length === 0)
  ) {
    return true;
  }
  return false;
};

/**
 * Check if the provided var is an object
 *
 * @param {object} object
 *
 * @return {boolean}
 */
export const isObject = object =>
  typeof object === 'object' &&
  object instanceof Object &&
  !Array.isArray(object);

/**
 * Check if the provided var is an array
 *
 * @param {any} value
 *
 * @return {boolean}
 */
export const isArray = value => Array.isArray(value);

/**
 * Check if the provided var is string
 *
 * @param {any} value
 *
 * @return {boolean}
 */
export const isString = value => typeof value === 'string';

/**
 * Check if the provided var is function
 *
 * @param {any} value
 *
 * @return {boolean}
 */
export const isFunction = value => typeof value === 'function';
