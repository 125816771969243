/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState, Suspense, lazy, useRef} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {useFlag} from 'react-unleash-flags';
import {AnimatePresence} from 'framer-motion';
import {useRouteMatch, useLocation} from 'react-router-dom';
import {
  Box,
  Confirmation,
  LinearProgress,
  Nameplate,
  useMediaQuery,
} from '@tenancy.nz/design-system';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga4';
import {doLogout, fetchProfileData, setSessionTime} from './actions/user';
import SubmitLoader from './utils/SubmissionLoader';
import Fade from './utils/animate/Fade';
import PageTransition from './utils/animate/PageTransition';
import {
  fetchAgencyDetails,
  fetchApplicationDetails,
  setReturnUrl,
  setAgentEmail,
} from './actions/agency';
import ErrorBoundary from './components/Error';
import {updateFormData} from './actions/form';
import {getPropertyFromUrl, prepareSettings} from './utils/helpers';
import {initGA} from './analytics';

initGA();

const ApplicationComplete = lazy(() =>
  import('./components/ApplicationForm/ApplicationComplete')
);
const ApplicationForm = lazy(() => import('./components/ApplicationForm'));
const ManualPropertySelection = lazy(() =>
  import('./components/ManualPropertySelection')
);
const PropertySelection = lazy(() => import('./components/PropertySelection'));
const PropertyDetail = lazy(() => import('./components/PropertyDetail'));
const PropertyDetailsCompact = lazy(() =>
  import('./components/PropertyDetailsCompact')
);
const TenantProfile = lazy(() => import('./components/TenantProfile'));
const Maintenance = lazy(() => import('./components/Maintenance'));
const NotFound = lazy(() => import('./components/NotFound'));
const DeletedProperty = lazy(() => import('./components/DeletedProperty'));
const Header = lazy(() => import('./components/Shared/Header'));
const Footer = lazy(() => import('./components/Shared/Footer'));

const StyledMainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  & > div:first-child {
    flex-grow: 1;
  }

  & .MuiGrid-item:empty {
    display: none;
  }
`;

const StyledNameplateWrapper = styled.div`
  & .MuiContainer-root {
    padding: 0;
  }
`;

const App = ({
  authenticated,
  agencyName,
  agencyLocation,
  agencyID,
  // eslint-disable-next-line no-unused-vars
  agencyPhone,
  agencyLogo,
  loading,
  hasStartedApplication,
  properties,
  property,
  fetchAgencyAction,
  fetchApplication,
  hasSelectedProperty,
  getProfileData,
  profileData,
  logout,
  pending,
  error,
  updating,
  postingToApi,
  complete,
  setFormData,
  skippedRegistration,
  storeReturnUrl,
  storeAgentEmail,
  sessionTime,
  setAuthSessionTime,
  settings,
  coapplicants,
  applicant,
}) => {
  const location = useLocation();
  const maintenanceModeFlag = useFlag('maintenance_mode');
  const isNotMobile = useMediaQuery(theme => theme.breakpoints.up('md'));
  const footerActionsRef = useRef(null);
  const {
    params: {agencyOrPropertyID, applicationID},
  } = useRouteMatch();

  const [showPageLoader, setShowPageLoader] = useState(false);
  const [showLogoutWarning, setShowLogoutWarning] = useState(false);
  const [showWrongProfileMessage, setShowWrongProfileMessage] = useState(false);
  const [showWrongProfileMessageData, setShowWrongProfileMessageData] =
    useState({name: '', email: ''});
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const elm = document.getElementById('root');
    const updateAuthTime = () => {
      setAuthSessionTime(new Date());
    };
    if (elm) {
      elm.addEventListener('click', updateAuthTime);
    }
    return () => elm.removeEventListener('click', updateAuthTime);
  }, []);

  useEffect(() => {
    if (profileData && applicant && coapplicants && authenticated) {
      const {
        profile: {email: profileEmail},
      } = profileData;

      if (
        String(applicant.email).toLowerCase() !==
          String(profileEmail).toLowerCase() &&
        coapplicants.length > 0
      ) {
        const mainApplicant = coapplicants.find(app => app.is_main === true);
        const name = mainApplicant
          ? `${mainApplicant.first_name} ${mainApplicant.last_name}`
          : 'main applicant';
        logout().then(() => {
          setShowWrongProfileMessage(true);
          setShowWrongProfileMessageData({name, email: applicant.email});
        });
      }
    }
  }, [authenticated, coapplicants, applicant, profileData]);

  useEffect(() => {
    let interval = null;
    if (authenticated) {
      interval = setInterval(() => {
        const dateObj = dayjs(sessionTime);
        const now = dayjs();
        // Logout user if no activity for 20 mins
        if (now.diff(dateObj) >= 30 * 60 * 1000) {
          logout().then(() => {
            setShowLogoutWarning(true);
          });
        }
      }, 300000);
    }

    return () => {
      if (interval) {
        return clearInterval(interval);
      }
      return null;
    };
  }, [sessionTime]);

  useEffect(() => {
    if (profileData === null && !complete && !skippedRegistration) {
      getProfileData();
    }
  }, [profileData, authenticated, complete, skippedRegistration]);

  useEffect(() => {
    const {search} = window.location;
    const params = new URLSearchParams(search);
    const returnUrl = params.get('r');
    const agentEmail = params.get('agent_email');
    storeAgentEmail(agentEmail);
    if (returnUrl) {
      storeReturnUrl(returnUrl);
    }
  }, []);

  useEffect(() => {
    if (
      !isNotMobile &&
      !!hasStartedApplication &&
      (authenticated || skippedRegistration)
    ) {
      setShowFooter(false);
    } else if (
      (properties && properties.length > 0) ||
      hasSelectedProperty ||
      error
    ) {
      setTimeout(() => setShowFooter(true), 1000);
    }
  }, [
    properties,
    hasSelectedProperty,
    error,
    hasStartedApplication,
    authenticated,
    skippedRegistration,
    isNotMobile,
  ]);

  useEffect(() => {
    if (applicationID) {
      setFormData({uuid: applicationID});
      fetchApplication(applicationID);
    } else if (!agencyID) {
      fetchAgencyAction(agencyOrPropertyID);
    }
  }, [fetchAgencyAction, agencyOrPropertyID, applicationID, agencyID]);

  // Gets property object
  const selProperty = property || getPropertyFromUrl(location);

  if (settings && !settings.active) {
    return <NotFound />;
  }

  if (maintenanceModeFlag && maintenanceModeFlag.enabled === true) {
    return <Maintenance />;
  }

  if (
    (!maintenanceModeFlag ||
      (maintenanceModeFlag && maintenanceModeFlag.enabled === false)) &&
    complete
  ) {
    return (
      <Suspense fallback={<LinearProgress />}>
        <Helmet>
          <title>Tenancy Application Complete</title>
        </Helmet>
        <Box>
          <Header
            logout={logout}
            authenticated={authenticated}
            pending={pending}
          />
          <StyledNameplateWrapper>
            <Nameplate
              title="These properties are managed by:"
              heading={agencyName}
              subHeading={agencyLocation}
              logo={agencyLogo}
              skeleton={loading || !!error}
            />
          </StyledNameplateWrapper>
          {isNotMobile && (
            <PropertyDetailsCompact
              address={selProperty.address || ''}
              // eslint-disable-next-line react/prop-types
              images={selProperty.images || []}
            />
          )}
          <ApplicationComplete address={selProperty.address || ''} />
        </Box>
      </Suspense>
    );
  }

  const handleWrongProfileMessageClose = () => {
    setShowWrongProfileMessage(false);
    setShowWrongProfileMessageData({name: '', email: ''});
  };

  return (
    <>
      {' '}
      <Helmet>
        <title>{agencyName && `${agencyName} | `} Tenancy Application</title>
      </Helmet>
      <SubmitLoader show={postingToApi} />
      <StyledMainContainer>
        <PageTransition
          showPageLoader={showPageLoader}
          setShowPageLoader={setShowPageLoader}>
          <Fade
            show={
              hasSelectedProperty && !hasStartedApplication && !isNotMobile
            }>
            <Header
              logout={logout}
              authenticated={authenticated}
              pending={pending}
            />
          </Fade>
          <Fade
            show={
              isNotMobile ||
              !(hasSelectedProperty && !hasStartedApplication && !isNotMobile)
            }>
            <Header
              logout={logout}
              authenticated={authenticated}
              pending={pending}
            />
            <StyledNameplateWrapper>
              <Nameplate
                title="These properties are managed by:"
                heading={agencyName}
                subHeading={agencyLocation}
                logo={agencyLogo}
                skeleton={loading || !!error}
              />
            </StyledNameplateWrapper>
          </Fade>
          <ErrorBoundary>
            <Suspense fallback={<LinearProgress />}>
              <AnimatePresence exitBeforeEnter>
                {(() => {
                  if (loading || updating) {
                    return (
                      <Fade key="loading" withAnimatePresence={false} show>
                        <LinearProgress />
                      </Fade>
                    );
                  }
                  if (property && property.status === 'deleted') {
                    return (
                      <DeletedProperty clientCode={property.client_code} />
                    );
                  }
                  if (
                    properties &&
                    properties.length > 0 &&
                    !hasSelectedProperty &&
                    !hasStartedApplication
                  ) {
                    return (
                      <Fade
                        key="PropertySelection"
                        withAnimatePresence={false}
                        show>
                        <PropertySelection />
                      </Fade>
                    );
                  }
                  // If no active properties found, show manual selection page
                  if (
                    properties &&
                    properties.length === 0 &&
                    !hasSelectedProperty &&
                    !hasStartedApplication
                  ) {
                    return (
                      <Fade
                        key="PropertySelection"
                        withAnimatePresence={false}
                        show>
                        <ManualPropertySelection />
                      </Fade>
                    );
                  }

                  if (hasSelectedProperty && !hasStartedApplication) {
                    return (
                      <Fade
                        key="PropertyDetail"
                        withAnimatePresence={false}
                        show>
                        <PropertyDetail
                          propertyData={property}
                          agencyID={agencyID}
                        />
                      </Fade>
                    );
                  }

                  if (
                    !!hasStartedApplication &&
                    !authenticated &&
                    !skippedRegistration
                  ) {
                    return (
                      <Fade
                        key="TenantProfile"
                        withAnimatePresence={false}
                        show>
                        <TenantProfile
                          property={{
                            address: selProperty.address || '',
                            id: selProperty.id || '',
                            // eslint-disable-next-line react/prop-types
                            images: selProperty.images || [],
                          }}
                        />
                      </Fade>
                    );
                  }
                  if (
                    !!hasStartedApplication &&
                    (authenticated || skippedRegistration)
                  ) {
                    return (
                      <Box>
                        <PropertyDetailsCompact
                          address={selProperty.address || ''}
                          // eslint-disable-next-line react/prop-types
                          images={selProperty.images || []}
                        />
                        <Fade
                          key="ApplicationForm"
                          withAnimatePresence={false}
                          show>
                          <ApplicationForm />
                        </Fade>
                      </Box>
                    );
                  }

                  return <NotFound error={error} />;
                })()}
              </AnimatePresence>
            </Suspense>
          </ErrorBoundary>
        </PageTransition>
        <Suspense fallback={<div />}>
          {!!hasStartedApplication &&
            (authenticated || skippedRegistration) &&
            !isNotMobile && (
              <>
                {/* <MobileFormActions forwardedRef={footerActionsRef} /> */}
                <div
                  style={{
                    height:
                      footerActionsRef && footerActionsRef.current
                        ? footerActionsRef.current.clientHeight
                        : 68,
                  }}
                />
              </>
            )}
          {showFooter && !loading && !updating && !showPageLoader && (
            <Fade show>
              <Footer />
            </Fade>
          )}
        </Suspense>
        <Confirmation
          title="You've been logged out"
          message="Due to inactivity you have been logged out to protect your privacy."
          open={showLogoutWarning}
          cancelBtnText=""
          onConfirm={() => setShowLogoutWarning(false)}
          onCancel={() => false}
        />
        <Confirmation
          title="You've been logged out"
          message={
            <>
              <p>
                It seems like you were logged in with incorrect profile. Please
                login or create a new account with{' '}
                <strong>{showWrongProfileMessageData.email}</strong> email
                address to submit this application.
              </p>
              <p>
                If you wish to use a different email,{' '}
                <strong>{showWrongProfileMessageData.name}</strong> needs to
                update your email in the Tenant Portal.
              </p>
            </>
          }
          open={!!showWrongProfileMessage}
          cancelBtnText=""
          onConfirm={() => handleWrongProfileMessageClose()}
          onCancel={() => false}
        />
      </StyledMainContainer>
    </>
  );
};

App.defaultProps = {
  agencyLogo: '',
  agencyName: '',
  agencyLocation: '',
  agencyID: null,
  agencyPhone: '',
  loading: true,
  hasStartedApplication: false,
  properties: null,
  property: null,
  authenticated: false,
  hasSelectedProperty: false,
  profileData: null,
  error: null,
  updating: false,
  postingToApi: false,
  pending: false,
  complete: false,
  skippedRegistration: false,
  sessionTime: null,
  settings: {
    active: true,
    dropdown: true,
    listings: true,
    manual: true,
  },
  coapplicants: [],
  applicant: {
    email: undefined,
  },
};

App.propTypes = {
  agencyLogo: PropTypes.string,
  agencyID: PropTypes.number,
  agencyName: PropTypes.string,
  agencyLocation: PropTypes.string,
  agencyPhone: PropTypes.string,
  fetchAgencyAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasStartedApplication: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  profileData: PropTypes.object,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      address: PropTypes.string,
      photo: PropTypes.string,
    })
  ),
  property: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    address: PropTypes.string,
    photo: PropTypes.string,
    status: PropTypes.string,
    client_code: PropTypes.number,
  }),
  fetchApplication: PropTypes.func.isRequired,
  authenticated: PropTypes.bool,
  hasSelectedProperty: PropTypes.bool,
  pending: PropTypes.bool,
  updating: PropTypes.bool,
  storeReturnUrl: PropTypes.func.isRequired,
  storeAgentEmail: PropTypes.func.isRequired,
  sessionTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(null),
  ]),
  setAuthSessionTime: PropTypes.func.isRequired,
  getProfileData: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Error),
  postingToApi: PropTypes.bool,
  complete: PropTypes.bool,
  skippedRegistration: PropTypes.bool,
  settings: PropTypes.shape({
    active: PropTypes.bool,
    dropdown: PropTypes.bool,
    listings: PropTypes.bool,
    manual: PropTypes.bool,
  }),
  coapplicants: PropTypes.arrayOf(PropTypes.shape({})),
  applicant: PropTypes.shape({
    email: PropTypes.string,
  }),
};

const mapDispatchToProps = dispatch => ({
  fetchAgencyAction: agencyOrPropertyID =>
    dispatch(fetchAgencyDetails(agencyOrPropertyID)),
  fetchApplication: applicationID =>
    dispatch(fetchApplicationDetails(applicationID)),
  setFormData: data => dispatch(updateFormData(data)),
  getProfileData: () => dispatch(fetchProfileData()),
  logout: () => dispatch(doLogout()),
  storeReturnUrl: returnUrl => dispatch(setReturnUrl(returnUrl)),
  storeAgentEmail: email => dispatch(setAgentEmail(email)),
  setAuthSessionTime: time => dispatch(setSessionTime(time)),
});

const mapStateToProps = state => {
  return {
    agency: state.agency,
    agencyID: state.agency.details.id,
    agencyName: state.agency.details.name,
    agencyLocation: state.agency.details.location,
    agencyPhone: state.agency.details.phone,
    agencyLogo: state.agency.details.logo,
    applicant: state.user.applicant,
    coapplicants: state.form.coapplicants,
    loading: state.agency.loading,
    hasStartedApplication: state.user.has.startedApplication,
    properties: state.properties,
    property: state.property,
    authenticated: state.user.auth.authenticated,
    skippedRegistration: state.user.has.skippedRegistration,
    hasSelectedProperty: state.user.has.selectedProperty,
    profileData: state.user.profile.data,
    error: state.agency.error,
    pending: state.user.auth.pending,
    updating: state.form.updating,
    postingToApi: state.form.submitting,
    complete: state.form.complete,
    sessionTime: state.user.auth.sessionTime,
    settings: prepareSettings(state.agency.settings),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
