import {combineReducers} from 'redux';

const auth = (
  state = {
    authenticated: false,
    error: null,
    pending: false,
    redirecting: false,
    isNewUser: false,
    sessionTime: null,
  },
  action
) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authenticated: action.authenticated,
        pending: false,
        error: null,
      };
    case 'LOGGING_IN':
    case 'REGISTERING':
      return {...state, pending: true};
    case 'LOGGING_OUT':
      return {...state, redirecting: true};
    case 'LOGIN_ERROR':
    case 'REGISTRATION_ERROR':
      return {
        authenticated: false,
        error: action.error,
        pending: false,
        redirecting: false,
      };
    case 'LOGOUT':
      return {
        authenticated: false,
        error: null,
        pending: false,
        redirecting: true,
      };
    case 'SET_SESSION_TIME':
      return {
        ...state,
        sessionTime: action.time,
      };
    default:
      return state;
  }
};

const profile = (state = {data: null, error: null}, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
    case 'REGISTRATION_SUCCESS':
    case 'USER_HAS_SKIPPED_REGISTRATION':
      return {...state, error: null};
    case 'LOGOUT':
      return {data: null, error: null};
    case 'SET_PROFILE_DATA':
      return {...state, data: action.data};
    case 'UPDATE_PROFILE_DATA':
      return {...state, data: {...state.data, ...action.data}};
    case 'SET_PROFILE_ERROR':
      return {...state, error: action.error};
    default:
      return state;
  }
};

const applicant = (state = null, action) => {
  switch (action.type) {
    case 'SET_APPLICANT':
      return action.applicant;
    default:
      return state;
  }
};

const has = (state = {}, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return {...state, startedApplication: false, justRegistered: false};
    case 'USER_HAS_SELECTED_PROPERTY':
      return {...state, selectedProperty: action.hasSelectedProperty};
    case 'USER_HAS_SKIPPED_REGISTRATION':
      return {
        ...state,
        skippedRegistration: action.skipped,
        startedApplication: action.skipped,
      };
    case 'USER_HAS_STARTED_APPLICATION':
      return {...state, startedApplication: action.started};
    case 'REGISTRATION_SUCCESS':
      return {...state, justRegistered: true};
    default:
      return state;
  }
};

const profileImageUrl = (state = '', action) => {
  switch (action.type) {
    case 'SET_PROFILE_PHOTO_URL':
      return action.profileImageUrl;
    default:
      return state;
  }
};

const firstName = (state = '', action) => {
  switch (action.type) {
    case 'SET_FIRST_NAME':
      return action.firstName;
    default:
      return state;
  }
};

const lastName = (state = '', action) => {
  switch (action.type) {
    case 'SET_LAST_NAME':
      return action.lastName;
    default:
      return state;
  }
};

const email = (state = '', action) => {
  switch (action.type) {
    case 'SET_EMAIL':
      return action.email;
    default:
      return state;
  }
};

const mobile = (state = '', action) => {
  switch (action.type) {
    case 'SET_MOBILE':
      return action.mobile;
    default:
      return state;
  }
};

const userReducer = combineReducers({
  auth,
  has,
  profile,
  applicant,
  profileImageUrl,
  firstName,
  lastName,
  email,
  mobile,
});

export default userReducer;
