import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {FlagsProvider} from 'react-unleash-flags';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {LinearProgress, ThemeProvider} from '@tenancy.nz/design-system';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './reducers';
import theme from './theme';

const NotFound = lazy(() => import('./components/NotFound'));

const store = configureStore();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            <Route path="/:agencyOrPropertyID/:applicationID?">
              <FlagsProvider>
                <App />
              </FlagsProvider>
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
