/* eslint-disable import/prefer-default-export */
import {sanitizeAddress} from './string';

export const getPropertyFromUrl = location => {
  const parts = [
    new URLSearchParams(location.search).get('street_1'),
    new URLSearchParams(location.search).get('street_2'),
    new URLSearchParams(location.search).get('suburb'),
    new URLSearchParams(location.search).get('city'),
    new URLSearchParams(location.search).get('postcode'),
  ];

  return {
    address: parts
      .map(part => (part && sanitizeAddress(part)) || '')
      .filter(part => !!part)
      .join(', '),
    id: null,
    images: [],
  };
};
